import React from "react";
import { graphql } from "gatsby";
import { Layout } from "~/templates/Layout";
import { DownloadCard } from "~/molecules/DownloadCard";
import { Container } from "~/atoms/Container";
import { PageHeading } from "~/atoms/PageHeading";
import { PATH } from "~/constant/path";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

interface Props {
  data: {
    allContentfulDownload: GatsbyTypes.ContentfulDownloadConnection;
  };
}

const Downloads: React.FC<Props> = ({ data }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          background: ${colors.bgGrayGradient};
        }
        .download :global(.container) {
          padding: 0 0 40px;
        }
        .downloadList {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          width: 100%;
        }
        @media (${breakPoints.sp}) {
          .listSection :global(section.section .sectionInner) {
            padding-top: 30px;
          }
        }
      `}</style>
      <Layout title={PATH.DOWNLOADS.name}>
        <div className="wrapper">
          <PageHeading title={PATH.DOWNLOADS.name} />
          <div className="download">
            <Container>
              <ul className="downloadList">
                {data.allContentfulDownload.edges.map((edge) => (
                  <DownloadCard
                    key={edge.node.contentful_id}
                    article={edge.node}
                    href={`/downloads/${edge.node.pathSlug}`}
                  />
                ))}
              </ul>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Downloads;

export const DownloadsQuery = graphql`
  query Downloads {
    allContentfulDownload(filter: { pathSlug: { ne: "contact" } }, sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          documentCover {
            fluid(maxWidth: 767) {
              src
              aspectRatio
            }
          }
          title
          contentful_id
          pathSlug
          createdAt
        }
      }
    }
  }
`;
